/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import routes from './routes';
import PrivateLayout from './layouts/PrivateLayout';

const history = createBrowserHistory();

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (<PrivateLayout><Component {...props} /></PrivateLayout>)}
  />
);

export default () => {
  React.useEffect(() => {
    const initialValue = document.body.style.zoom;
    document.body.style.zoom = 0.85;
    return () => {
      // Restore default value
      document.body.style.zoom = initialValue;
    };
  });
  return (
    <Router history={history} basename={'/app'} >
      {
        routes.map((route) => {
          const {
            path, exact, component, isPublic = false,
          } = route;
          return isPublic
            ? <Route key={path} {...route} />
            : <PrivateRoute key={path} path={path} component={component} exact={exact} />;
        })}
    </Router>
  );
}

