import { GET_TQ, UPDATE_TQ, UPDATE_TQ_ANSWER, GET_TQ_MODEL } from '../actions/tqActions';

const initState = {
  survey: null,
  tqModel: null,
};

const tqReducer = (state = initState, action) => {
  switch(action.type) {
    case GET_TQ_MODEL:
      return {
        ...state,
        tqModel: action.payload,
      };
    case GET_TQ:
      return {
        ...state,
        survey: action.payload,
      };
    case UPDATE_TQ:
      return {
        ...state,
        survey: action.payload,
      }
    case UPDATE_TQ_ANSWER:
        return state;
    default:
      return state;
  }
}

export default tqReducer;