import PropTypes from 'prop-types';

export default PropTypes.shape({
  tq_id: PropTypes.string,
  tq_model_id: PropTypes.string,
  team_id: PropTypes.string,
  value: PropTypes.string,
  color: PropTypes.string,
  sequence: PropTypes.string,
  sent: PropTypes.bool,
  sent_at: PropTypes.string,
  deadline_at: PropTypes.string,
  deadline_passed: PropTypes.bool,
  closed: PropTypes.bool,
  published: PropTypes.bool,
  created: PropTypes.string,
  created_by: PropTypes.string,
});