import React from 'react';
import PropTypes from 'prop-types';
import {
  CentralBox,
  HeaderTitle,
  StyledDivider,
  BottomBlueBox,
  NextButton,
  BackButton,
  BackButtonMobile,
  TopBlueBox,
} from '../common/index.styled';
import {
  Typography,
  FormControl,
} from '@material-ui/core';
import { setContent, setStep } from '../../store/actions/navigationActions';
import Translate from '../Translate/Translate';
import { DoubleArrow, PlayArrow, KeyboardBackspace } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  formControlResource: {
    marginTop: theme.spacing(3),
    textAlign: 'center',
    width: '75%',
  },
  text: {
    color: '#5F5F5F',
    fontSize: '20px',
  },
  triangleIcon: {
    fontSize: '80px',
    color: '#CCBD74',
    textAlign: 'center',
    margin: 'auto',
    transform: 'rotate(-90deg)',
  },
}));

const SurveyIntro = ({ setContent, setStep }) => {
  const classes = useStyles();
  
  const handleBackClick = () => {
    setContent('teamPicker');
    setStep(0);
  };

  const handleNextClick = () => {
    setContent('survey');
    setStep(2);
  };

  return (
    <CentralBox>
      <TopBlueBox>
      <BackButtonMobile variant='contained' onClick={() => handleBackClick()}>
        <KeyboardBackspace />
        <Translate keyValue='back_label' feature='teamquality/app/button' />
      </BackButtonMobile>
      <NextButton
          onClick={() => handleNextClick()}
        >
          <Translate keyValue='start_label' feature='teamquality/app/button' />
          <DoubleArrow />
        </NextButton>
      </TopBlueBox>
      <BackButton variant='contained' onClick={() => handleBackClick()}>
        <KeyboardBackspace />
        <Translate keyValue='back_label' feature='teamquality/app/button' />
      </BackButton>
      <HeaderTitle>
        <Translate keyValue='app_name' feature='teamquality/app' />
      </HeaderTitle>
      <StyledDivider />
      <FormControl className={classes.formControlResource}>
        <PlayArrow className={classes.triangleIcon} />
      </FormControl>
      <FormControl className={classes.formControlResource}>
        <Typography className={classes.text}>
          <Translate keyValue='info_text_1' feature='teamquality/app/survey' />
        </Typography>
      </FormControl>
      <FormControl className={classes.formControlResource}>
        <Typography className={classes.text}>
          <Translate keyValue='info_text_2' feature='teamquality/app/survey' />
        </Typography>
      </FormControl>
      <FormControl className={classes.formControlResource}>
        <Typography className={classes.text}>
          <Translate keyValue='info_text_3' feature='teamquality/app/survey' />
        </Typography>
      </FormControl>
      <FormControl className={classes.formControlResource}>
        <Typography className={classes.text}>
          <Translate keyValue='info_text_4' feature='teamquality/app/survey' />
        </Typography>
      </FormControl>
      <BottomBlueBox>
        <NextButton
          onClick={() => handleNextClick()}
        >
          <Translate keyValue='start_label' feature='teamquality/app/button' />
          <DoubleArrow />
        </NextButton>
      </BottomBlueBox>
    </CentralBox>
  );
};

SurveyIntro.propTypes = {
  setStep: PropTypes.func,
  setContent: PropTypes.func,
};

SurveyIntro.defaultProps = {
  setStep: () => null,
  setContent: () => null,
};

const mapDispatchToProps = (dispatch) =>({
  setStep: (step) => dispatch(setStep(step)),
  setContent: (content) => dispatch(setContent(content)),
})

export default connect(null, mapDispatchToProps)(SurveyIntro);