import Login from './containers/Login/Login';
import Homepage from './containers/Homepage/Homepage';
import DataCollector from './components/DataCollector/DataCollector';

const routes = [
  { path: '/login', component: Login, isPublic: true, exact: true },
  { path: '/login/start/:team_id', component: DataCollector, isPublic: true },
  { path: '/login/take/:team_id', component: DataCollector, isPublic: true },
  { path: '/', component: Homepage, exact: true },
]

export default routes;