import React from 'react';
import PropTypes from 'prop-types';
import { StyledInput, FormInputContainer, StyledInputError } from './index.styled';

const FormInput = props => {
  const { value, type, placeholder, error, onChange } = props;
  const handleInputChange = e => {
    onChange(e.target.value);
  };
  return (
    <FormInputContainer>
      <StyledInput
        {...props}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={(e) => handleInputChange(e)}
        error={!!error}
        variant="outlined"
      />
      {error && (
        <StyledInputError>{error}</StyledInputError>
      )}
    </FormInputContainer>
  )
};

FormInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
};

FormInput.defaultProps = {
  label: '',
  value: '',
  type: 'text',
  placeholder: '',
  error: '',
  onChange: () => null,
};

export default FormInput;