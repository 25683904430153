import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Translate from '../Translate/Translate';
import Flag from 'react-world-flags'
import { logout } from '../../store/actions/authActions';
import { connect } from 'react-redux';
import { getLanguageResources, setLanguage } from '../../store/actions/languageActions';
import { SKILLNAD_URL } from '../../constants/urls';
import logo from '../../assets/img/logo.png';
import { getTQS } from '../../store/actions/tqActions';
import currentUserType from '../../types/currentUser';
import surveyType from '../../types/survey';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  icon: {
    color: '#FFFFFF'
  },
  avatar: {
    width: '250px',
    position: 'absolute',
    bottom: '15px',
  },
});

const HamburgerMenu = ({ logout, getLanguageResources, setLanguage, getTQS, currentUser, activeSurvey }) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleLanguageClick = (language_id) => {
    if(currentUser.user_id) {
      setLanguage(currentUser.user_id, language_id);
    }
    if(activeSurvey.tq_id) {
      setTimeout(() => {
        getTQS(activeSurvey.tq_id);
      }, 250);
    }
    getLanguageResources(language_id);
  }

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem button onClick={() => handleLanguageClick("1")}>
          <ListItemIcon>
            <Flag style={{ width: '25px' }} code={'SE'} />
          </ListItemIcon>
          <ListItemText primary={'Svenska'} />
        </ListItem>
        <ListItem button onClick={() => handleLanguageClick("2")}>
          <ListItemIcon>
            <Flag style={{ width: '25px' }} code={'GB'} />
          </ListItemIcon>
          <ListItemText primary={'English'} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button onClick={() => window.open(SKILLNAD_URL, '_blank')}>
          <ListItemIcon>
            <InfoOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary={<Translate keyValue='about_tqs_label' feature='teamquality/app' />} />
        </ListItem>
        <ListItem button onClick={() => logout()}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary={<Translate keyValue='logout_label' feature='teamquality/app' />} />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div>
      <React.Fragment key={'right'}>
        <IconButton style={{ marginRight: '12px' }} onClick={toggleDrawer('right', true)}>
          <MenuIcon className={classes.icon} />
        </IconButton>
        <Drawer anchor={'right'} open={state['right']} onClose={toggleDrawer('right', false)}>
          {list('right')}
          <Avatar className={classes.avatar} variant='square' alt="logo" src={logo} />
        </Drawer>
      </React.Fragment>
    </div>
  );
}

HamburgerMenu.propTypes = {
  logout: PropTypes.func,
  getLanguageResources: PropTypes.func,
  setLanguage: PropTypes.func,
  getTQS: PropTypes.func,
  currentUser: currentUserType,
  activeSurvey: surveyType,
};

HamburgerMenu.defaultProps = {
  logout: () => null,
  getLanguageResources: () => null,
  setLanguage: () => null,
  getTQS: () => null,
  currentUserType: {},
  activeSurvey: {},
};

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
  activeSurvey: state.survey.activeSurvey,
});

const mapDispatchToProps = (dispatch) =>({
  logout: () => dispatch(logout()),
  getLanguageResources: (language_id) => dispatch(getLanguageResources(language_id)),
  setLanguage: (user_id, language_id) => dispatch(setLanguage(user_id, language_id)),
  getTQS: (tq_id) => dispatch(getTQS(tq_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HamburgerMenu);