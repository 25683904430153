import { loginEmailPassword, resetUser } from '../../services/userService';
import { getUserInfo } from '../../services/teamqualityService';
import { SET_SELECTED_TEAM, SET_TEAM_FROM_LINK } from '../actions/teamActions';
import { SET_STEP, SET_CONTENT } from '../actions/navigationActions';

export const LOGIN = 'LOGIN';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGOUT = 'LOGOUT';
export const RESET_USER = 'RESET_USER';
export const FORBIDDEN_API = 'FORBIDDEN_API';

export const login = (username, password) => (dispatch) => {
  dispatch({ type: FORBIDDEN_API, payload: null });
  const data = {
    username,
    password,
  };
  const noAuthOptions = {
    headers: {
      'content-type': 'application/json',
    }
  };
  sessionStorage.clear();
  loginEmailPassword({ data, noAuthOptions }).then((response) => {
    sessionStorage.setItem('token', response.data.token);
    sessionStorage.setItem('userId', response.data.user_id);
    dispatch({ type: LOGIN });
  }).then(() => {
    const userId = sessionStorage.getItem('userId');
    const options = {
      headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
      },
    };
    getUserInfo({ userId, options }).then((response) => {
      dispatch({ type: SET_CURRENT_USER, payload: response.data });
    });
  }).catch((error) => {
    dispatch({ type: AUTH_ERROR, payload: error.message });
  })
};

export const resetAuthError = () => (dispatch) => {
  dispatch({ type: AUTH_ERROR, payload: null });
}

export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT });
  dispatch({ type: SET_CURRENT_USER, payload: {} });
  dispatch({ type: SET_SELECTED_TEAM, payload: {} });
  dispatch({ type: SET_STEP, payload: 0 });
  dispatch({ type: SET_CONTENT, payload: 'teamPicker' });
  dispatch({ type: FORBIDDEN_API, payload: null });
  dispatch({ type: SET_TEAM_FROM_LINK, payload: null });
  sessionStorage.clear();
};

export const resetUserAccount = (username) => (dispatch) => {
  const data = {
    username,
  }

  const noAuthOptions = {
    headers: {
      'content-type': 'application/json',
    },
  };

  resetUser({ data, noAuthOptions }).then(() => {
    dispatch({ type: RESET_USER });
  })
};
