import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Nouislider from "nouislider-react";
import { HappyAvatar, SadAvatar } from '../common/index.styled';
import sad from '../../assets/img/sad_face.png';
import happy from '../../assets/img/happy_face.png';
import "nouislider/distribute/nouislider.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    zoom: '1.17'
  },
  margin: {
    height: theme.spacing(3),
  },
  icon: {
    color: '#CCBD74',
    marginLeft: '15px',
    fontSize: '35px',
  }
}));

const MySlider = ({ myValue, onChange, question, index }) => {
  const classes = useStyles();

  const onUpdate = () => (value) => {
    myValue = `${value[0]}00`
    onChange(question, myValue);
    document.getElementsByClassName('noUi-handle')[index].style.visibility = 'visible';
  };

  return (
    <div className={classes.root}>
      <SadAvatar src={sad} />
      <Nouislider
            style={{width: '100%', marginTop: '10px', height: '13px'}}
            start={0}
            connect={[true, false]}
            orientation="horizontal"
            range={{
              min: 0,
              max: 6
            }}
            step={.0001}
            onSlide={onUpdate()}
          />
      <HappyAvatar src={happy} />
    </div>
  )
}

export default MySlider;