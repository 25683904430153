import React from 'react';
import PropTypes from 'prop-types';
import { CentralBox, LoginLargeButton, HeaderTitle, StyledDivider, BackButton, BottomBlueBox, TextButtonLight } from '../common/index.styled';
import FormInput from '../common/FormInput';
import { useDispatch } from 'react-redux';
import { resetUserAccount } from '../../store/actions/authActions';
import get from 'lodash/get';
import omit from 'lodash/omit';
import { useAsync } from 'react-async';
import Translate from '../Translate/Translate';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';


const formDataFields = {
  email: 'email',
};

const isVerified = async ([email]) => {
  if (email) {
    try {
      if (true) {
        return true;
      }
    } catch (err) {
      return false;
    }
  }
  // Return default value as false
  return false;
};

const ResetPassword = ({ setActiveContent }) => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState({});
  const [errors, setErrors] = React.useState({});

  const handleFormDataChange = (field, value) => {
    setData({
      ...data,
      [field]: value,
    });
    setErrors({ ...omit(errors, [field]), })
  };

  const { run: validateEmail } = useAsync({
    deferFn: isVerified,
    onResolve: isValid => {
      if (isValid) {
        dispatch(resetUserAccount(data.email));
        setActiveContent('confirmation');
        return;
      }
      setErrors({ [formDataFields.email]: 'Invalid email' })
    }
  })

  return (
    <CentralBox>
      <BackButton variant='contained' onClick={() => setActiveContent('')}>
        <KeyboardBackspaceIcon />
        <Translate keyValue='back_label' feature='teamquality/app/button' />
      </BackButton>
      <HeaderTitle>
        <Translate keyValue='title' feature='teamquality/app/login/reset' />
      </HeaderTitle>
      <StyledDivider />
      <FormInput
        label={<Translate keyValue='emailaddress' feature='teamquality/app' />}
        value={get(data, formDataFields.email, '')}
        error={get(errors, formDataFields.email)}
        onChange={(value) => handleFormDataChange(formDataFields.email, value)}
        className='inputRounded'
      />
      <LoginLargeButton
        variant='contained'
        onClick={() => validateEmail(get(data, formDataFields.email))}
      >
        <Translate keyValue='ok_label' feature='teamquality/app/button' />
      </LoginLargeButton>
      <BottomBlueBox>
        <TextButtonLight
          variant='text'
          onClick={() => setActiveContent('privacyPolicy')}
        >
          <Translate keyValue='privacy_policy_label' feature='teamquality/app/login' />
        </TextButtonLight>
      </BottomBlueBox>
    </CentralBox>
  );
};

ResetPassword.propTypes = {
  onSubmit: PropTypes.func,
};

ResetPassword.defaultProps = {
  onSubmit: () => null,
};

export default ResetPassword;