import React from 'react';
import PropTypes from 'prop-types'; 
import { Toolbar, Popper, Grow, Paper, ClickAwayListener, MenuItem, MenuList } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import LanguagePicker from '../LanguagePicker/LanguagePicker';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu';
import currentUserType from '../../types/currentUser';
import { logout } from '../../store/actions/authActions';
import { connect } from 'react-redux';
import { StyledNavbar, MenuContainer, MobileMenuContainer, StyledAvatar } from '../common/index.styled';
import MyStepper from '../Stepper/Stepper';
import Translate from '../Translate/Translate';
import { SKILLNAD_URL } from '../../constants/urls';
import logo from '../../assets/img/logo_light.png';

const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: 'rgba(0,0,0,0.5) !important',
    borderRadius: '10%',
    color: '#FFFFFF',
    marginTop: '25px',
    top: '9px !important',
  },
  icon: {
    color: '#FFFFFF',
  },
  nameButton: {
    textTransform: 'capitalize',
    color: '#FFFFFF',
  }
}));

const Navbar = ({ currentUser, step, signOut }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const classes = useStyles();

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <StyledNavbar position="static">
        <Toolbar>
          <StyledAvatar src={logo} />
          <MyStepper active={step} />
          <MobileMenuContainer>
            <HamburgerMenu />
          </MobileMenuContainer>
          <MenuContainer>
          <Button
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            className={classes.nameButton}
          >
            {currentUser.fullname}
          </Button>
          <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper className={classes.paper}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    <MenuItem onClick={() => window.open(SKILLNAD_URL, '_blank')}>
                      <Translate keyValue='about_tqs_label' feature='teamquality/app' />
                    </MenuItem>
                    <MenuItem onClick={() => signOut()}>
                      <Translate keyValue='logout_label' feature='teamquality/app' />
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <LanguagePicker style={{ bottom: '8px', right: '15px' }} />
        </MenuContainer>
        </Toolbar>
      </StyledNavbar>
    </div>
  )
};

Navbar.propTypes = {
  currentUser: currentUserType,
  step: PropTypes.number,
  signOut: PropTypes.func,
};

Navbar.defaultProps = {
  currentUser: {},
  step: 0,
  signOut: () => null,
};

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
  step: state.navigation.currentStep,
});

const mapDispatchToProps = (dispatch) => ({
  signOut: () => dispatch(logout()),
}); 

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);