import React from 'react';
import { StyledTransparentNavbar } from '../common/index.styled';
import LanguagePicker from '../LanguagePicker/LanguagePicker';
import { Toolbar } from '@material-ui/core';

const TransparentNavbar = () => {


  return (
    <div>
      <StyledTransparentNavbar position="static">
        <Toolbar>
          <LanguagePicker />
        </Toolbar>
      </StyledTransparentNavbar>
    </div>
  )
};

export default TransparentNavbar;