import PropTypes from 'prop-types';

export default PropTypes.shape({
  resource_data_id: PropTypes.string,
  feature: PropTypes.string,
  key: PropTypes.string,
  data: PropTypes.string,
  page_type_id: PropTypes.string,
  page_type: PropTypes.string,
  resource_id: PropTypes.string,
  language_id: PropTypes.string,
});