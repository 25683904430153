import styled from 'styled-components';
import * as devices from '../../assets/styles/devices';
import { TextField, FormControl, Button, AppBar, Typography, Divider, Paper, Stepper, Avatar } from '@material-ui/core';
import bg1 from '../../assets/img/bg2.jpg';
import bg from '../../assets/img/bg.jpg';

export const PageContainer = styled.div.attrs({
  className: 'PageContainer'
})`
  width: 100%;
  height: 100%;
  background-image: url(${bg1});
  background-size: cover;
  @media ${`(min-width: ${devices.size.medium}px)`} {
    width: 100%;
    height: 100%;
    background-image: url(${bg1});
    background-size: cover;
  }
`;

export const LoginPageContainer = styled.div.attrs({
  className: 'LoginPageContainer'
})`
  width: 100%;
  height: 100%;
  background-image: url(${bg});
  background-size: cover;
  @media ${`(min-width: ${devices.size.medium}px)`} {
    width: 100%;
    height: 100%;
    background-image: url(${bg});
    background-size: cover;
  }
`;

export const StyledInputError = styled.span`
  color: ${({ fontColor = '#CC5858' }) => fontColor} !important;
  padding: 6px 0 0 8px;
  font-size: 10px;
`;

export const FormInputContainer = styled.div`
  display: grid;
  text-align: left;
  font-size: 13px;
  margin: ${({ margin = '0 0 10px 0' }) => margin};
  justify-content: center;
  margin-top: 35px;
`;

export const StyledInputLabel = styled.div`
  padding: 12px 0;
  font-size: 17px;
`;

export const StyledInput = styled(TextField)`
  width: 250px;
`;

export const CentralBox = styled.div.attrs({
  className: 'CentralBox'
})`
  text-align: center;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #FFFFFF;
  @media ${`(min-width: ${devices.size.medium}px)`} {
    width: 504px;
    height: 749px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0px;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    background-color: #FFFFFF;
    border-radius: 50px;
  }
`;

export const BottomBlueBox = styled.div.attrs({
  className: 'BottomBlueBox'
})`
  display: none;
  @media ${`(min-width: ${devices.size.medium}px)`} {
    width: 504px;
    height: 70px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    background-color: #76B1B9;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    display: block;
  }
`;

export const TopBlueBox = styled.div.attrs({
  className: 'TopBlueBox'
})`
  text-align: center;
  width: 100%;
  height: 60px !important;
  background-color: #76B1B9;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  @media ${`(min-width: ${devices.size.medium}px)`} {
    display: none;
  }
`;

export const AppNameContainer = styled.div.attrs({
  className: 'AppNameContainer'
})`
  display: none;
  @media ${`(min-width: ${devices.size.medium}px)`} {
    width: 100%;
    height: 30px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
  }
`;

export const StyledFormControl = styled(FormControl)`
    padding-top: 35px !important;
    align-items: center;
    width: 100%;
`

export const InputField = styled(TextField)`
    width: 297px;
`
export const LoginLargeButton = styled(Button)`
  background-color: #5D7477 !important;
  font-size: 20px !important;
  color: #FFFFFF !important;
  text-transform: uppercase !important;
  width: 250px;
  height: 56px;
  border-radius: 29px !important;
  margin-top: 15px !important;
  @media ${`(min-width: ${devices.size.medium}px)`} {
    background-color: #5D7477 !important;
    font-size: 20px !important;
    color: #FFFFFF !important;
    text-transform: uppercase !important;
    width: 250px;
    height: 56px;
    border-radius: 29px !important;
    margin-top: 55px !important;
  }
`;

export const TextButtonDark = styled(Button)`
  font-size: 12px !important;
  color: #76B1B9 !important;
  text-transform: capitalize !important;
  margin-top: 40px !important;
`; 

export const TextButtonLight = styled(Button)`
  font-size: 12px !important;
  color: #FFFFFF !important;
  text-transform: capitalize !important;
  margin-top: 20px !important;
`; 

export const StyledNavbar = styled(AppBar)`
  background-color: #76B1B9 !important;
  height: 65px;
`;

export const StyledTransparentNavbar = styled(AppBar)`
  background-color: rgba(0, 0, 0, 0.0) !important;
  height: 0px;
`;

export const HeaderTitle = styled(Typography)`
  color: #76B1B9 !important;
  font-size: 35px !important;
  font-weight: 200 !important;
  margin-top: 60px !important;
  margin-bottom: 20px !important;
  @media ${`(min-width: ${devices.size.medium}px)`} {
    color: #76B1B9 !important;
    font-size: 35px !important;
    font-weight: 200 !important;
    margin-top: 80px !important;
    margin-bottom: 20px !important;
  }
`;

export const StyledDivider = styled(Divider)`
  background-color: #76B1B9 !important;
  width: 280px;
  margin: auto !important;
  @media ${`(min-width: ${devices.size.medium}px)`} {
    background-color: #76B1B9 !important;
    width: 321px;
    margin: auto !important;
  }
`;

export const SubHeader = styled(Typography)`
  color: #76B1B9 !important;
  font-size: 20px !important;
  text-transform: capitalize;
  font-weight: 200 !important;
  font-style: italic !important;
  text-align: center;
`;

export const TeamPickButton = styled(Button)`
  border-top-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
  height: 40px;
  width: 270px !important;
`;

export const TeamPickPaper = styled(Paper)`
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  width: 270px !important;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const NextButton = styled(Button)`
  border-radius: 20px !important;
  width: 100px !important;
  background-color: #5D7477 !important;
  color: #FFFFFF !important;
  text-transform: capitalize !important;
  height: 45px;
  bottom: 23%;
  &:hover {
    width: 130px !important;
  }
`;

export const SendButton = styled(Button)`
  border-radius: 20px !important;
  width: 100px !important;
  background-color: #CCBD74 !important;
  color: #000000 !important;
  text-transform: capitalize !important;
  height: 45px;
  bottom: 23%;
  &:hover {
    width: 130px !important;
  }
`;

export const StyledStepper = styled(Stepper)`
  display: none;
  @media ${`(min-width: ${devices.size.medium}px)`} {
    display: block;
  }
`;

export const AppTitle = styled(Typography)`
  display: none;
  @media ${`(min-width: ${devices.size.medium}px)`} {
    font-size: 25px !important;
    color: #FFFFFF;
    text-align: center;
    width: 100%;
    position: absolute;
    top: 93%;
    font-weight: 700 !important;
    display: block;
  }
`;

export const BackButton = styled(Button)`
  display: none !important;
  @media ${`(min-width: ${devices.size.medium}px)`} {
    float: left;
    margin-left: 50px !important;
    margin-top: -3px !important;
    background-color: #CCBD74 !important;
    text-transform: capitalize !important;
    display: inline-flex !important;
  }
`;

export const BackButtonMobile = styled(Button)`
  background-color: #CCBD74 !important;
  text-transform: capitalize !important;
  border-radius: 20px !important;
  height: 45px;
  bottom: 23%;
  right: 10px;
  @media ${`(min-width: ${devices.size.medium}px)`} {
    display: none;
  }
`;

export const PrivacyPolicyContainer = styled.div.attrs({ className: "AgendaContainer" })`
  width: 80%;
  height: 53%;
  display: flex;
  justify-content: center;
  text-align: left;
  overflow-y: auto;
  margin: auto;
  margin-top: 20px;
  @media ${`(min-width: ${devices.size.medium}px)`} {
    width: 80%;
    height: 430px;
    display: flex;
    text-align: left;
    justify-content: center;
    overflow-y: auto;
    margin: auto;
    margin-top: 20px;
  }
`;

export const HalfRoundedButton = styled(Button)`
  border-bottom-right-radius: 25px !important;
  border-top-right-radius: 25px !important;
  background-color: #5D7477 !important;
  font-size: 15px !important;
  margin-left: 3px !important;
  color: #FFFFFF !important;
  text-transform: capitalize !important;
  height: 55px;
`;

export const CalendarContainer = styled(FormControl)`
    display: none !important;
    @media ${`(min-width: ${devices.size.medium}px)`} {
      text-align: center;
      display: block !important;
  }
`
export const MobileCalendarContainer = styled(FormControl)`
    text-align: center;
    display: block !important;
    @media ${`(min-width: ${devices.size.medium}px)`} {
      display: none !important;
  }
`

export const MobileMenuContainer = styled.div.attrs({
  className: 'MobileMenuContainer'
})`
  display: block;
  right: 5px;
  position: absolute;
  @media ${`(min-width: ${devices.size.medium}px)`} {
    display: none;
  }
`;

export const MenuContainer = styled.div.attrs({
  className: 'MenuContainer'
})`
  display: none;
  @media ${`(min-width: ${devices.size.medium}px)`} {
    display: block;
  }
`;

export const StyledAvatar = styled(Avatar)`
    display: none !important;
    @media ${`(min-width: ${devices.size.medium}px)`} {
      display: block !important;
      width: 210px !important;
  }
`

export const LoginAvatar = styled(Avatar)`
  width: 210px !important;
  margin: auto;
`

export const SurveyContainer = styled(FormControl)`
    width: 95% !important;
    margin-top: 32px !important;
    text-align: center;
    width: 100% !important;
    max-height: 65% !important;
    overflow-y: auto !important;
    @media ${`(min-width: ${devices.size.medium}px)`} {
      margin-top: 20px !important;
      text-align: center !important;
      width: 75% !important;
      max-height: 58% !important;
      overflow-y: auto !important;
  }
`

export const SadAvatar = styled(Avatar)`
  margin-right: 15px;
  width:30px !important;
  height: 30px !important;
  margin-top: 8px;
`

export const HappyAvatar = styled(Avatar)`
  margin-left: 15px;
  width:30px !important;
  height: 30px !important;
  margin-top: 8px;
`