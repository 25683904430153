import PropTypes from 'prop-types';
import organizationType from '../organization';

export default PropTypes.shape({
  user_id: PropTypes.string,
  fullname: PropTypes.string,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  role: PropTypes.string,
  language: PropTypes.string,
  time_zome: PropTypes.string,
  org_list: PropTypes.arrayOf(organizationType)
});