import React from 'react';
import PropTypes from 'prop-types';
import { CentralBox, HeaderTitle, StyledDivider, LoginLargeButton } from '../common/index.styled';
import DraftsIcon from '@material-ui/icons/Drafts';
import Translate from '../Translate/Translate';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    fontSize: '44px',
    color: '#707070',
    width: '100%',
  },
  text: {
    color: '#76B1B9 !important',
    width: '80%',
    margin: 'auto',
    fontSize: '21px',
  }
}));

const ResetPasswordConfirm = ({ setActiveContent }) => {
  const classes = useStyles();

  return (
    <CentralBox>
      <HeaderTitle>
        <Translate keyValue='title' feature='teamquality/app/login/reset' />
      </HeaderTitle>
      <StyledDivider />
      <DraftsIcon className={classes.icon} />
      <Typography className={classes.text}>
        <Translate keyValue='text' feature='teamquality/app/login/reset' />
      </Typography>
      <LoginLargeButton onClick={() => setActiveContent('')}>
        <Translate keyValue='ok_label' feature='teamquality/app/button' />
      </LoginLargeButton>
    </CentralBox>
  );
};

ResetPasswordConfirm.propTypes = {
  onSubmit: PropTypes.func,
};

ResetPasswordConfirm.defaultProps = {
  onSubmit: () => null,
};

export default ResetPasswordConfirm;