import { combineReducers } from 'redux'
import authReducer from './authReducer';
import teamReducer from './teamReducer';
import stepReducer from './navigationReducer';
import langaugeReducer from './languageReducer';
import surveyReducer from './surveyReducer';
import tqReducer from './tqReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  team: teamReducer,
  navigation: stepReducer,
  language: langaugeReducer,
  survey: surveyReducer,
  tq: tqReducer,
});

export default rootReducer;