import { getTeamInfo, addTeamMember, removeTeamMember } from '../../services/teamqualityService';
import { FORBIDDEN_API } from './authActions';

export const SET_SELECTED_TEAM = 'SET_SELECTED_TEAM';
export const SET_TEAM_FROM_LINK = 'SET_TEAM_FROM_LINK';
export const ADD_MEMBER = 'ADD_MEMBER';
export const REMOVE_MEMBER = 'REMOVE_MEMBER';

export const setSelectedTeam = (teamId) => (dispatch) => {
    const options = {
      headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
      },
    };
    getTeamInfo({ teamId, options }).then((response) => {
      dispatch({ type: SET_SELECTED_TEAM, payload: response.data });
      dispatch({ type: FORBIDDEN_API, payload: null });
    }).catch((error) => {
      dispatch({ type: FORBIDDEN_API, payload: error.message });
    })
};

export const addMember = (username, team_id) => (dispatch) => {
    const data = {
      username,
      team_id,
    };
  
    const options = {
      headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
      },
    };
  
    addTeamMember({ data, options }).then((response) => {
      dispatch({ type: ADD_MEMBER })
    }).catch((error) => {
      dispatch({ type: FORBIDDEN_API, payload: error.message });
    });
};

export const removeMember = (member_id) => (dispatch) => {

    const options = {
      headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
      },
    };
  
    removeTeamMember({ member_id, options }).then((response) => {
      dispatch({ type: REMOVE_MEMBER })
    }).catch((error) => {
      dispatch({ type: FORBIDDEN_API, payload: error.message });
    });
};

export const setTeamFromLink = (team_id) => (dispatch) => {
  dispatch({ type: SET_TEAM_FROM_LINK, payload: team_id });
};