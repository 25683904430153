import moment from 'moment';

export const SET_DEADLINE = 'SET_DEADLINE';
export const SET_REMINDER = 'SET_REMINDER';
export const SET_ACTIVE_SURVEY = 'SET_ACTIVE_SURVEY';

export const setDeadline = (deadline) => (dispatch) => {
  const reminder = moment(deadline).add(-2, 'days');
  dispatch({ type: SET_DEADLINE, payload: moment(deadline).format('YYYY-MM-DD') });
  dispatch({ type: SET_REMINDER, payload: moment(reminder).format('YYYY-MM-DD') });
};

export const setActiveSurvey = (survey) => (dispatch) => {
  dispatch({ type: 'SET_ACTIVE_SURVEY', payload: survey });
}