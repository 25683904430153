import React from 'react';
import { CentralBox, HeaderTitle, StyledDivider,BottomBlueBox } from '../common/index.styled';
import { FormControl, Typography } from '@material-ui/core';
import Translate from '../Translate/Translate';
import { makeStyles } from '@material-ui/core/styles';
import { DoneAll } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  formControlResource: {
    marginTop: theme.spacing(4),
    textAlign: 'center',
    width: '62%',
  },
  clearIcon: {
    fontSize: '80px',
    color: '#CCBD74',
    textAlign: 'center',
    margin: 'auto',
  },
  textLarge: {
    color: '#76B1B9',
    fontSize: '21px',
  },
}));

const NothingToDo = () => {
  const classes = useStyles(); 

  return (
    <CentralBox>
      <HeaderTitle>
        <Translate keyValue='app_name' feature='teamquality/app' />
      </HeaderTitle>
      <StyledDivider />
      <FormControl className={classes.formControlResource}>
        <DoneAll className={classes.clearIcon} />
      </FormControl>
      <FormControl className={classes.formControlResource}>
        <Typography className={classes.textLarge}>
          <Translate keyValue='nothing_to_do' feature='teamquality/app/survey/error' />
        </Typography>
      </FormControl>
      <FormControl className={classes.formControlResource}>
        <Typography className={classes.textLarge}>
          <Translate keyValue='text_3' feature='teamquality/app/confirmation' />
        </Typography>
      </FormControl>
      <BottomBlueBox />
    </CentralBox>
  )
};

export default NothingToDo;