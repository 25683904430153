import { SET_CONTENT, SET_STEP } from '../actions/navigationActions';

const initState = {
  currentStep: 0,
  currentContent: 'teamPicker',
};

const stepReducer = (state = initState, action) => {
  switch(action.type) {
    case SET_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };
    case SET_CONTENT:
      return {
        ...state,
        currentContent: action.payload,
      }
    default:
      return state;
  }
};

export default stepReducer;