import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CentralBox, HeaderTitle, StyledDivider, SubHeader, BackButton, BottomBlueBox, NextButton, CalendarContainer, MobileCalendarContainer, BackButtonMobile, TopBlueBox } from '../common/index.styled';
import currentUserType from '../../types/currentUser';
import teamType from '../../types/team';
import { FormControl, Typography, Chip } from '@material-ui/core';
import { setContent, setStep } from '../../store/actions/navigationActions';
import { setDeadline } from '../../store/actions/surveyActions';
import Translate from '../Translate/Translate';
import { makeStyles } from '@material-ui/core/styles';
import { Event, KeyboardBackspace, DoubleArrow, ErrorOutline, InfoOutlined } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import 'date-fns';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  formControlResource: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
    width: '62%',
  },
  info: {
    bottom: '100px',
    position: 'absolute',
    left: 0,
    right: 0,
    margin: 'auto',
    width: '70%',
  },
  calendarMobile: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
    width: '62%',
  },
  calendar: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
    width: '62%',
  },
  peopleIcon: {
    fontSize: '44px',
    color: '#CCBD74',
    textAlign: 'center',
    margin: 'auto',
  },
  leaderIcon: {
    color: '#CCBD74',
  },
  deleteIcon: {
    marginLeft: '35px',
  },
  text: {
    color: '#5F5F5F',
    fontSize: '16px',
  },
  inputContainer: {
    marginTop: '15px',
  },
  halfRoundedField: {
    borderBottomLeftRadius: '25px !important',
    borderTopLeftRadius: '25px !important'
  },
  list: {
    overflowY: 'auto',
    maxHeight: '200px',
  }
}));

const Deadline = ({ setDeadline, setContent, setStep, deadline }) => {
  const classes = useStyles();
  const [date, setDate] = React.useState((deadline) ? (deadline) : (moment().add(2, 'days').format('YYYY-MM-DD')));
  const [error, setError] = React.useState(false);

  const handleDateChange = (date) => {
    if(moment(date).isSameOrAfter(new Date(), 'day')) {
      setError(false);
      setDate(date);
      setDeadline(date);
    } else {
      setDate(date);
      setDeadline(moment().format('YYYY-MM-DD'));
      setError(true);
    }
  };

  const handleNextClick = () => {
    setContent('review');
    setStep(3);
  };

  const handleBackClick = () => {
    setContent('memberManagement');
    setStep(1);
  };

  return (
    <CentralBox>
      <TopBlueBox>
        <BackButtonMobile variant='contained' onClick={() => handleBackClick()}>
          <KeyboardBackspace />
          <Translate keyValue='back_label' feature='teamquality/app/button' />
        </BackButtonMobile>
        <NextButton
          variant='contained'
          disabled={!date || error}
          onClick={() => handleNextClick()}
        >
          <Translate keyValue='next_label' feature='teamquality/app/button' />
          <DoubleArrow />
        </NextButton>
      </TopBlueBox>
      <BackButton variant='contained' onClick={() => handleBackClick()}>
        <KeyboardBackspace />
        <Translate keyValue='back_label' feature='teamquality/app/button' />
      </BackButton>
      <HeaderTitle>
        <Translate keyValue='title' feature='teamquality/app/invite' />
      </HeaderTitle>
      <StyledDivider />
      <FormControl className={classes.formControlResource} >
        <SubHeader>
          <Translate keyValue='deadline_subtitle_1' feature='teamquality/app/invite' />
        </SubHeader>
      </FormControl>
      <FormControl className={classes.formControlResource}>
        <Event className={classes.peopleIcon} />
      </FormControl>
      <FormControl className={classes.formControlResource}>
        <Typography className={classes.text}>
          <Translate keyValue='deadline_text_1' feature='teamquality/app/invite' />
        </Typography>
      </FormControl>
      <MobileCalendarContainer>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="space-around">
          <KeyboardDatePicker
          margin="normal"
          id="date-picker-dialog"
          label={<Translate keyValue='deadline_subtitle_1' feature='teamquality/app/invite' />}
          format="yyyy-MM-dd"
          value={date}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
          </Grid>
        </MuiPickersUtilsProvider>
      </MobileCalendarContainer>
      <CalendarContainer>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="space-around">
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="yyyy-MM-dd"
              margin="normal"
              id="date-picker-inline"
              label={<Translate keyValue='deadline_subtitle_1' feature='teamquality/app/invite' />}
              value={(date) ? (date) : (moment().format('YYYY-MM-DD'))}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </CalendarContainer>
      <FormControl className={classes.info}>
        {
          (error) ? (
            <Chip
            variant="outlined"
            size="small"
            label={<Translate keyValue='date_in_past' feature='teamquality/app/invite/error' />}
            icon={<ErrorOutline />}
            color="secondary"
          />
          ) : (
            <Chip
              variant="outlined"
              size="small"
              icon={<InfoOutlined />}
              label={<Translate keyValue='deadline_text_2' feature='teamquality/app/invite' />}
            />
          )
        }
      </FormControl>
      <BottomBlueBox>
        <NextButton
          variant='contained'
          disabled={!date || error}
          onClick={() => handleNextClick()}
        >
          <Translate keyValue='next_label' feature='teamquality/app/button' />
          <DoubleArrow />
        </NextButton>
      </BottomBlueBox>
    </CentralBox>
  )
};

Deadline.propTypes = {
  currentUser: currentUserType,
  selectedTeam: teamType,
  setContent: PropTypes.func,
  setStep: PropTypes.func,
  setDeadline: PropTypes.func,
  deadline: PropTypes.string,
};

Deadline.defaultProps = {
  currentUser: {},
  selectedTeam: {},
  setContent: () => null,
  setStep: () => null,
  setDeadline: () => null,
  deadline: '',
};

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
  selectedTeam: state.team.selectedTeam,
  deadline: state.survey.deadline,
});

const mapDistpatchToProps = (dispatch) => ({
  setContent: (content) => dispatch(setContent(content)),
  setStep: (step) => dispatch(setStep(step)),
  setDeadline: (deadline) => dispatch(setDeadline(deadline)),
});

export default connect(mapStateToProps, mapDistpatchToProps)(Deadline);