import { SET_SELECTED_TEAM, SET_TEAM_FROM_LINK, ADD_MEMBER, REMOVE_MEMBER } from '../actions/teamActions';

const initState = {
  selectedTeam: {},
  teamFromLink: null,
};

const teamReducer = (state = initState, action) => {
  switch(action.type) {
    case SET_SELECTED_TEAM:
      return {
        ...state,
        selectedTeam: action.payload,
      };
    case SET_TEAM_FROM_LINK:
      return {
        ...state,
        teamFromLink: action.payload,
      };
    case ADD_MEMBER:
      return state;
    case REMOVE_MEMBER:
      return state;
    default:
      return state;
  }
}

export default teamReducer;