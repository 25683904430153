import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { CentralBox, HeaderTitle, StyledDivider, SubHeader, TeamPickButton, TeamPickPaper, BottomBlueBox, NextButton, TopBlueBox } from '../common/index.styled';
import currentUserType from '../../types/currentUser';
import resourceType from '../../types/resource';
import teamType from '../../types/team';
import surveyType from '../../types/survey';
import surveyFullType from '../../types/surveyFull';
import { setSelectedTeam } from '../../store/actions/teamActions';
import { setContent, setStep } from '../../store/actions/navigationActions';
import { setActiveSurvey } from '../../store/actions/surveyActions';
import { getTQS, getTqModelById } from '../../store/actions/tqActions';
import { setLanguage } from '../../store/actions/languageActions';
import { FormControl, MenuItem, Popper, Grow, ClickAwayListener, MenuList, Divider, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Translate from '../../components/Translate/Translate';
import { ExpandMore, ErrorOutline } from '@material-ui/icons';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControlSelect: {
    marginTop: theme.spacing(5),
    margin: theme.spacing(1),
    minWidth: 200,
  },
  formControlResource: {
    marginTop: theme.spacing(2),
    textAlign: 'justify',
    width: '65%',
  },
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    color: '#CCBD74',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  info: {
    bottom: '150px',
    position: 'absolute',
    left: 0,
    right: 0,
    margin: 'auto',
    width: '70%',
  },
}));

const TeamPicker = ({
  currentUser,
  selectedTeam,
  teamFromLink,
  apiError,
  setSelectedTeam,
  setContent,
  setStep,
  setActiveSurvey,
  activeSurvey,
  getTQS,
  getTqModelById,
  survey,
  setLanguage,
  resources
}) => {
  const [teams, setTeams] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const classes = useStyles();
  const [isLeader, setIsLeader] = React.useState(false);
  const [userAnswered, setUserAnswered] = React.useState(false);
  const [surveyId, setSurveyId] = React.useState('');

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const getTeams = async () => {
    const allTeams = [];
    await currentUser.org_list.forEach(organization => {
      organization.team_list.forEach(team => {
        allTeams.push(team);
      });
    });
    setTeams(allTeams);
  }

  const handleNextClick = () => {
    if(!activeSurvey.sent) {
      if (isLeader) {
        setContent('memberManagement');
        setStep(1);
      } else {
        setContent('nothingToDo');
        setStep(4);
      }
    } else {
      if(!userAnswered) {
        setContent('surveyIntro');
        setStep(1);
      } else {
        setContent('alreadyAnswered');
        setStep(4);
      }
    }
  }

  React.useEffect(() => {
    getTeams();
    if (teamFromLink) {
      setSelectedTeam(teamFromLink);
    }
    if(selectedTeam.members) {
      selectedTeam.members.forEach((member) => {
        if(member.user_id === currentUser.user_id) {
          setIsLeader(member.leader);
        }
      })
      setActiveSurvey({});
      if(selectedTeam.team_quality_surveys.length > 0) {
        selectedTeam.team_quality_surveys.forEach(tq => {
          if(!tq.closed) {
            setActiveSurvey(tq);
          }
        });
      }
    }
    if (survey && survey.answers.length > 0) {
      const tq_answer = survey.answers.filter((object) => {
        return object.user_id === currentUser.user_id;
      });
      if(tq_answer[0] !== undefined) {
        if(tq_answer[0].answered) {
          setUserAnswered(true);
        } else {
          setUserAnswered(false);
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, selectedTeam, setActiveSurvey, setSelectedTeam, survey, teamFromLink, userAnswered]);

  React.useEffect(() => {
    if(currentUser.user_id) {
      setLanguage(currentUser.user_id, resources[0].language_id)
    }
    if(activeSurvey.tq_id) {
      getTQS(activeSurvey.tq_id);
      getTqModelById(activeSurvey.tq_model_id);
    }
  }, [activeSurvey, currentUser, getTQS, resources, setLanguage, getTqModelById]);

  React.useEffect(() => {
    if(selectedTeam.team_id) {
      selectedTeam.team_quality_surveys.forEach(tq => {
        if(!tq.closed) {
          setSurveyId(tq.tq_id);
        }
      });
    }
  }, [selectedTeam, surveyId]);

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  return (
    <CentralBox>
      <TopBlueBox>
      <NextButton
          variant='contained'
          onClick={() => handleNextClick()}
          disabled={(surveyId === '' && selectedTeam.team_id) || !selectedTeam.team_id}
        >
          <Translate keyValue='next_label' feature='teamquality/app/button' />
          <DoubleArrowIcon />
        </NextButton>
      </TopBlueBox>
      <HeaderTitle>
        {(activeSurvey.tq_id && !activeSurvey.sent) ? (
          <Translate keyValue='title' feature='teamquality/app/invite' />
        ) : (
          <Translate keyValue='app_name' feature='teamquality/app' />
        )}
      </HeaderTitle>
      <StyledDivider />
      <FormControl className={classes.formControlResource} >
        <SubHeader>
          <Translate keyValue='header' feature='teamquality/app/survey' />
        </SubHeader>
      </FormControl>
      {
        (apiError) ? (
          <strong>Please choose another team.</strong>
        ) : (
          <span />
        )
      }
      {
        (!teamFromLink || (teamFromLink && apiError)) ? (
        <FormControl className={classes.formControlSelect}>
          <TeamPickButton
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          variant='outlined'
        >
          {
            (selectedTeam.team) ? (selectedTeam.team) : (<Translate keyValue='team_picker_label' feature='teamquality/app/invite' />)
          }
          <ExpandMore className={clsx(classes.expand, {
                  [classes.expandOpen]: open,
                })}
                />
        </TeamPickButton>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <TeamPickPaper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    {teams.map((team) => {
                      return (
                      <div key={team.team_id}>
                        <MenuItem onClick={() => {
                          setSelectedTeam(team.team_id);
                          setOpen(false);
                          }
                          }
                          style={{ textTransform: 'uppercase', color: 'grey' }}>{team.team}</MenuItem>
                        <Divider variant='middle' />
                      </div>
                      )
                    })}
                  </MenuList>
                </ClickAwayListener>
              </TeamPickPaper>
            </Grow>
          )}
        </Popper>
        </FormControl>
        ) : (
          <p>{selectedTeam.team}</p>
        )
      }
      <FormControl className={classes.info}>
        {
          (surveyId === '' && selectedTeam.team_id) ? (
            <Chip
            variant="outlined"
            size="small"
            label={<Translate keyValue='no_eligible_survey' feature='teamquality/app/invite/error' />}
            icon={<ErrorOutline />}
            color="secondary"
          />
          ) : (
            <span />
          )
        }
      </FormControl>
      <BottomBlueBox style={{ height: '100px' }}>
        <NextButton
          variant='contained'
          onClick={() => handleNextClick()}
          disabled={(surveyId === '' && selectedTeam.team_id) || !selectedTeam.team_id}
        >
          <Translate keyValue='next_label' feature='teamquality/app/button' />
          <DoubleArrowIcon />
        </NextButton>
      </BottomBlueBox>
    </CentralBox>
  )
};

TeamPicker.propTypes = {
  currentUser: currentUserType,
  selectedTeam: teamType,
  teamFromLink: PropTypes.string,
  apiError: PropTypes.string,
  setSelectedTeam: PropTypes.func,
  setContent: PropTypes.func,
  setStep: PropTypes.func,
  resources: PropTypes.arrayOf(resourceType),
  setActiveSurvey: PropTypes.func,
  activeSurvey: surveyType,
  getTQS: PropTypes.func,
  survey: surveyFullType,
  setLanguage: PropTypes.func,
};

TeamPicker.defaultProps = {
  currentUser: {},
  selectedTeam: {},
  teamFromLink: null,
  apiError: null,
  setSelectedTeam: () => null,
  setContent: () => null,
  setStep: () => null,
  resources: [],
  setActiveSurvey: () => null,
  activeSurvey: {},
  getTQS: () => null,
  survey: {},
  setLanguage: () => null,
};

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
  selectedTeam: state.team.selectedTeam,
  teamFromLink: state.team.teamFromLink,
  apiError: state.auth.apiError,
  resources: state.language.resources,
  activeSurvey: state.survey.activeSurvey,
  survey: state.tq.survey,
});

const mapDistpatchToProps = (dispatch) => ({
  setSelectedTeam: (teamId) => dispatch(setSelectedTeam(teamId)),
  setContent: (content) => dispatch(setContent(content)),
  setStep: (step) => dispatch(setStep(step)),
  setActiveSurvey: (survey) => dispatch(setActiveSurvey(survey)),
  getTQS: (tqs_id) => dispatch(getTQS(tqs_id)),
  getTqModelById: (tq_model_id) => dispatch(getTqModelById(tq_model_id)),
  setLanguage: (user_id, language_id) => dispatch(setLanguage(user_id, language_id)),
});

export default connect(mapStateToProps, mapDistpatchToProps)(TeamPicker);