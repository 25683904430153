import { GET_LANGUAGES, GET_RESOURCES, SET_USER_LANGUAGE } from '../actions/languageActions';

const initState = {
  languages: [],
  resources: [],
  userLanguage: null,
};

const languageReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_LANGUAGES:
      return {
        ...state,
        languages: action.payload,
      };
    case GET_RESOURCES:
      return {
        ...state,
        resources: action.payload,
      }
    case SET_USER_LANGUAGE:
      return {
        ...state,
        userLanguage: action.payload,
      }
    default:
      return state;
  }
}

export default languageReducer;