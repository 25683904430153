import PropTypes from 'prop-types';

export default PropTypes.shape({
  language_id: PropTypes.string,
  priority: PropTypes.string,
  language: PropTypes.string,
  iso_639_1: PropTypes.string,
  flag_image: PropTypes.string,
  checksum: PropTypes.string,
  updated: PropTypes.string,
});