import PropTypes from 'prop-types';
import modelAreaType from '../modelArea';
import questionType from '../question';

export default PropTypes.shape({
  tq_id: PropTypes.string,
  tq_model_id: PropTypes.string,
  team_id: PropTypes.string,
  value: PropTypes.string,
  color: PropTypes.string,
  sequence: PropTypes.string,
  sent: PropTypes.bool,
  send_at: PropTypes.string,
  sent_at: PropTypes.string,
  deadline_at: PropTypes.string,
  deadline_passed: PropTypes.string,
  deadline_sent_at: PropTypes.string,
  closed: PropTypes.bool,
  published: PropTypes.bool,
  published_at: PropTypes.string,
  created: PropTypes.string,
  created_user_id: PropTypes.string,
  tq_model_areas: PropTypes.arrayOf(modelAreaType),
  list_top: PropTypes.arrayOf(questionType),
  list_bottom: PropTypes.arrayOf(questionType),
  reminder: PropTypes.bool,
  reminder_sent_at: PropTypes.string,
});