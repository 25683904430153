import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Translate from '../Translate/Translate';
import surveyFullType from '../../types/surveyFull';
import currentUserType from '../../types/currentUser';
import { updateTQAnswer } from '../../store/actions/tqActions';
import { setContent, setStep } from '../../store/actions/navigationActions';
import {
  CentralBox,
  HeaderTitle,
  StyledDivider,
  BottomBlueBox,
  SendButton,
  BackButton,
  BackButtonMobile,
  TopBlueBox,
  SubHeader,
  SurveyContainer
} from '../common/index.styled';
import { DoubleArrow, KeyboardBackspace, ErrorOutline } from '@material-ui/icons';
import MySlider from '../../components/common/Slider';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, Typography, Chip } from '@material-ui/core';
import Loader from 'react-loader-spinner'

const useStyles = makeStyles((theme) => ({
  text: {
    color: '#76B1B9',
    fontSize: '14px',
    textAlign: 'center'
  },
  errorMessage: {
    marginTop: '20px',
  },
  bars: {
    marginTop: '100px',
  }
}));


const Survey = ({ currentUser, survey, updateTQAnswer, setStep, setContent }) => {
  const classes = useStyles();
  const [answers] = React.useState([]);
  const [questions] = React.useState([]);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const handleClickBack = () => {
    setContent('surveyIntro');
    setStep(1);
  };

  const eventHandler = (question, data) => {
    setError(false);
    const object = {
      tq_model_question_id: parseInt(question),
      answer: data,
    }
    const index = answers.findIndex((e) => e.tq_model_question_id === object.tq_model_question_id);
    if(index === -1) {
      answers.push(object)
    } else {
      answers[index] = object;
    }
  }

  const handleClickSend = () => {
    const tq_answer = survey.answers.filter((object) => {
      return object.user_id === currentUser.user_id;
    })
    if (answers.length !== 35) {
      setError(true)
    } else {
      setError(false)
      updateTQAnswer(tq_answer[0].tq_answer_id, answers);
      setContent('tqsConfirmation');
      setStep(4);
    }
  }

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 4000)
  });

  React.useEffect(() => {
    survey.tq_model_areas.forEach((tq_model_area) => {
      tq_model_area.list_bottom.forEach((question) => {
        questions.push(question);
      });
    });
  }, [questions, survey]);

  return (loading) ? (
      <Loader
        type="BallTriangle"
        color="#CCBD74"
        height={100}
        width={100}
        timeout={4000}
        className={classes.bars}
      />
  ) : (
    <CentralBox>
      <TopBlueBox>
        <BackButtonMobile
          onClick={() => handleClickBack()}
        >
          <KeyboardBackspace />
          <Translate keyValue='back_label' feature='teamquality/app/button' />
        </BackButtonMobile>
        <SendButton
          onClick={() => handleClickSend()}
        >
          <Translate keyValue='send_label' feature='teamquality/app/button' />
          <DoubleArrow />
        </SendButton>
      </TopBlueBox>
      <BackButton
        onClick={() => handleClickBack()}
      >
        <KeyboardBackspace />
        <Translate keyValue='back_label' feature='teamquality/app/button' />
      </BackButton>
      <HeaderTitle>
        <Translate keyValue='app_name' feature='teamquality/app' />
      </HeaderTitle>
      <StyledDivider />
      <SurveyContainer>
        <List>
          {
            (survey) ? (
              survey.tq_model_areas.map((model_area) => {
                return (
                  <List key={model_area.tq_model_area_id}>
                    <SubHeader>{model_area.area}</SubHeader>
                    {
                      model_area.list_bottom.sort((a, b) => {
                        return a.tq_model_question_id - b.tq_model_question_id;
                      }).map((question) => {
                        return (
                          <ListItem key={question.tq_model_question_id}>
                            <ListItemText>
                              <Typography className={classes.text}>
                                {question.question}
                              </Typography>
                              <MySlider index={parseInt(question.tq_model_question_id) - 1} question={question.tq_model_question_id} onChange={eventHandler} />
                            </ListItemText>
                          </ListItem>
                        )
                      })
                    }
                  </List>
                )
              })
            ) : (
              <span />
            )
          }
        </List>
      </SurveyContainer>
      {
        (error) ? (
          <Chip
            className={classes.errorMessage}
            variant="outlined"
            size="small"
            label={<Translate keyValue='not_all_answers' feature='teamquality/app/survey/error' />}
            icon={<ErrorOutline />}
            color="secondary"
          />
        ) : (
          <span />
        )
      }
      <BottomBlueBox>
        <SendButton
          onClick={() => handleClickSend()}
        >
          <Translate keyValue='send_label' feature='teamquality/app/button' />
          <DoubleArrow />
        </SendButton>
      </BottomBlueBox>
    </CentralBox>
  );
};

Survey.propTypes = {
  currentUser: currentUserType,
  updateTQAnswer: PropTypes.func,
  survey: surveyFullType,
  setStep: PropTypes.func,
  setContent: PropTypes.func,
};

Survey.defaultProps = {
  currentUser: {},
  updateTQAnswer: () => null,
  survey: {},
  setStep: () => null,
  setContent: () => null,
};

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
  survey: state.tq.survey,
})

const mapDispatchToProps = (dispatch) => ({
  updateTQAnswer: (tq_id, answers) => dispatch(updateTQAnswer(tq_id, answers)),
  setStep: (step) => dispatch(setStep(step)),
  setContent: (content) => dispatch(setContent(content)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Survey);