import { getAllLanguages, getResources } from '../../services/languageService';
import { setUserLanguage } from '../../services/userService';

export const GET_LANGUAGES = 'GET_LANGUAGES';
export const GET_RESOURCES = 'GET_RESOURCES';
export const SET_USER_LANGUAGE = 'SET_USER_LANGUAGE';

const options = {
  headers: {
    'content-type': 'application/json',
  }
};

export const getLanguages = () => (dispatch) => {
  getAllLanguages({ options }).then((response) => {
    dispatch({ type: GET_LANGUAGES, payload: response.data });
  });
};

export const getLanguageResources = (language_id) => (dispatch) => {
  getResources({ language_id, options }).then((response) => {
    dispatch({ type: GET_RESOURCES, payload: response.data });
  });
};

export const setLanguage = (user_id, language_id) => (dispatch) => {
    const data = {
      language_id
    };
  
    const options = {
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
      },
    };
  
    setUserLanguage({ user_id, data, options }).then((response) => {
      dispatch({ type: SET_USER_LANGUAGE, payload: response.data.language_id })
    });
}