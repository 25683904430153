import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CentralBox, HeaderTitle, StyledDivider, SubHeader, BackButton, HalfRoundedButton, BottomBlueBox, NextButton, TopBlueBox, BackButtonMobile } from '../common/index.styled';
import currentUserType from '../../types/currentUser';
import teamType from '../../types/team';
import { TextField, FormControl, Typography, List, ListItem, ListItemText, ListItemIcon, Divider } from '@material-ui/core';
import { setContent, setStep } from '../../store/actions/navigationActions';
import { addMember, removeMember } from '../../store/actions/teamActions';
import { setSelectedTeam } from '../../store/actions/teamActions';
import Translate from '../Translate/Translate';
import { makeStyles } from '@material-ui/core/styles';
import { GroupAdd, KeyboardBackspace, Star, HighlightOff, DoubleArrow } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  formControlResource: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
    width: '62%',
  },
  members: {
    marginTop: theme.spacing(0),
    textAlign: 'center',
    maxWidth: '90%',
  },
  peopleIcon: {
    fontSize: '50px',
    color: '#CCBD74',
    textAlign: 'center',
    margin: 'auto',
  },
  leaderIcon: {
    color: '#CCBD74',
  },
  deleteIcon: {
    marginLeft: '35px',
  },
  text: {
    color: '#5F5F5F',
    fontSize: '16px',
  },
  inputContainer: {
    marginTop: '15px',
  },
  halfRoundedField: {
    borderBottomLeftRadius: '25px !important',
    borderTopLeftRadius: '25px !important'
  },
  list: {
    overflowY: 'auto',
    maxHeight: '200px',
  },
  subHeader: {
    textTransform: 'none',
  },
}));

const MemberManagement = ({ addMember, removeMember, selectedTeam, setContent, setStep, setSelectedTeam }) => {
  const [newMember, setNewMember] = React.useState('');
  const classes = useStyles();

  const handleOnChange = (e) => {
    setNewMember(e.target.value);
  }

  const handleBackClick = () => {
    setContent('teamPicker');
    setStep(0);
  }

  const handleNextClick = () => {
    setContent('deadline');
    setStep(2);
  }

  const handleRemove = (member_id) => {
    removeMember(member_id);
    setTimeout(() => {
      setSelectedTeam(selectedTeam.team_id);
    }, 500);
  }

  const handleAdd = (username) => {
    addMember(username, selectedTeam.team_id)
    setNewMember('');
    setTimeout(() => {
      setSelectedTeam(selectedTeam.team_id);
    }, 500);
  }

  return (
    <CentralBox>
      <TopBlueBox>
      <BackButtonMobile variant='contained' onClick={() => handleBackClick()}>
        <KeyboardBackspace />
        <Translate keyValue='back_label' feature='teamquality/app/button' />
      </BackButtonMobile>
      <NextButton
          variant='contained'
          disabled={!selectedTeam.team}
          onClick={() => handleNextClick()}
        >
          <Translate keyValue='next_label' feature='teamquality/app/button' />
          <DoubleArrow />
        </NextButton>
      </TopBlueBox>
      <BackButton variant='contained' onClick={() => handleBackClick()}>
        <KeyboardBackspace />
        <Translate keyValue='back_label' feature='teamquality/app/button' />
      </BackButton>
      <HeaderTitle>
        <Translate keyValue='title' feature='teamquality/app/invite' />
      </HeaderTitle>
      <StyledDivider />
      <FormControl className={classes.formControlResource} >
        <SubHeader className={classes.subHeader}>
          <Translate keyValue='add_members_subtitle' feature='teamquality/app/invite' />
        </SubHeader>
      </FormControl>
      <FormControl className={classes.formControlResource}>
        <GroupAdd className={classes.peopleIcon} />
      </FormControl>
      <FormControl className={classes.formControlResource}>
        <Typography className={classes.text}>
          <Translate keyValue='add_members_text' feature='teamquality/app/invite' />
        </Typography>
      </FormControl>
      <div className={classes.inputContainer}>
        <TextField
          id="outlined-basic"
          label={<Translate keyValue='emailaddress' feature='teamquality/app' />}
          variant="outlined"
          onChange={handleOnChange}
          value={newMember}
          className='inputHalfRounded'
        />
        <HalfRoundedButton onClick={() => handleAdd(newMember)}>
          <Translate keyValue='add_label' feature='teamquality/app/button' />
        </HalfRoundedButton>
      </div>
      <FormControl className={classes.formControlResource}>
        <Typography className={classes.text}>
          <Translate keyValue='member_list_label' feature='teamquality/app/invite' />:
        </Typography>
      </FormControl>
      <FormControl className={classes.members}>
        <List component="nav" aria-label="main mailbox folders">
          <div className={classes.list}>
          {
            selectedTeam.members.map((member) => {
              return(
                (member.leader) ? (
                  <div>
                    <ListItem>
                      <ListItemIcon>
                        <Star className={classes.leaderIcon} />
                      </ListItemIcon>
                      <ListItemText primary={member.username} />
                    </ListItem>
                    <Divider variant='middle' />
                  </div>
                ) : (
                  <span />
                )
              )
            })
          }
          {
            selectedTeam.members.map((member) => {
              return (
                <div>
                  {
                      (member.leader) ? (
                        <span />
                      ) : (
                        <ListItem>
                          <ListItemText primary={member.username} />
                          <ListItemIcon>
                            <HighlightOff onClick={() => handleRemove(member.member_id)} className={classes.deleteIcon} />
                          </ListItemIcon>
                        </ListItem>
                      )
                    }
                  <Divider variant='middle' />
                </div>
              )
            })
          }
          </div>
        </List>
      </FormControl>
      <BottomBlueBox>
        <NextButton
          variant='contained'
          disabled={!selectedTeam.team}
          onClick={() => handleNextClick()}
        >
          <Translate keyValue='next_label' feature='teamquality/app/button' />
          <DoubleArrow />
        </NextButton>
      </BottomBlueBox>
    </CentralBox>
  )
};

MemberManagement.propTypes = {
  currentUser: currentUserType,
  selectedTeam: teamType,
  setContent: PropTypes.func,
  setStep: PropTypes.func,
  setSelectedTeam: PropTypes.func,
  addMember: PropTypes.func,
  removeMember: PropTypes.func,
};

MemberManagement.defaultProps = {
  currentUser: {},
  selectedTeam: {},
  setContent: () => null,
  setStep: () => null,
  setSelectedTeam: () => null,
  addMember: () => null,
  removeMember: () => null,
};

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
  selectedTeam: state.team.selectedTeam,
});

const mapDistpatchToProps = (dispatch) => ({
  setContent: (content) => dispatch(setContent(content)),
  setStep: (step) => dispatch(setStep(step)),
  setSelectedTeam: (team_id) => dispatch(setSelectedTeam(team_id)),
  addMember: (username, team_id) => dispatch(addMember(username, team_id)),
  removeMember: (member_id) => dispatch(removeMember(member_id)),
});

export default connect(mapStateToProps, mapDistpatchToProps)(MemberManagement);