import React from 'react';
import PropTypes from 'prop-types';
import { CentralBox, HeaderTitle, StyledDivider,BottomBlueBox } from '../common/index.styled';
import { FormControl, Typography, Button } from '@material-ui/core';
import Translate from '../Translate/Translate';
import { makeStyles } from '@material-ui/core/styles';
import { DoneAll } from '@material-ui/icons';
import { getTQS } from '../../store/actions/tqActions';
import { setContent, setStep } from '../../store/actions/navigationActions';
import surveyType from '../../types/survey';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  formControlResource: {
    marginTop: theme.spacing(4),
    textAlign: 'center',
    width: '62%',
  },
  clearIcon: {
    fontSize: '80px',
    color: '#CCBD74',
    textAlign: 'center',
    margin: 'auto',
  },
  textLarge: {
    color: '#76B1B9',
    fontSize: '21px',
  },
  button: {
    color: '#000000',
    fontSize: '21px',
    textTransform: 'capitalize',
    backgroundColor: '#CCBD74 !important',
    borderRadius: '20px',
    bottom: '10px',
  },
}));

const Confirmation = ({ activeSurvey, getTQS, setStep, setContent }) => {
  const classes = useStyles(); 

  const handleClickTakeSurvey = () => {
    getTQS(activeSurvey.tq_id);
    setStep(1);
    setContent('surveyIntro');
  }

  return (
    <CentralBox>
      <HeaderTitle>
        <Translate keyValue='header' feature='teamquality/app/confirmation' />
      </HeaderTitle>
      <StyledDivider />
      <FormControl className={classes.formControlResource}>
        <DoneAll className={classes.clearIcon} />
      </FormControl>
      <FormControl className={classes.formControlResource}>
        <Typography className={classes.textLarge}>
          <Translate keyValue='text_1' feature='teamquality/app/confirmation' />
        </Typography>
      </FormControl>
      <FormControl className={classes.formControlResource}>
        <Typography className={classes.textLarge}>
          <Translate keyValue='text_3' feature='teamquality/app/confirmation' />
        </Typography>
      </FormControl>
      <FormControl className={classes.formControlResource}>
        <Button className={classes.button} onClick={handleClickTakeSurvey}>
          <Translate keyValue='take_survey' feature='teamquality/app/invite' />
        </Button>
      </FormControl>
      <BottomBlueBox />
    </CentralBox>
  )
};

Confirmation.propTypes = {
  activeSurvey: surveyType,
  getTQS: PropTypes.func,
  setStep: PropTypes.func,
  setContent: PropTypes.func,
};

Confirmation.defaultProps = {
  activeSurvey: {},
  getTQS: () => null,
  setStep: () => null,
  setContent: () => null,
};

const mapStateToProps = (state) => ({
  activeSurvey: state.survey.activeSurvey,
});

const mapDispatchToProps = (dispatch) => ({
  getTQS: (tq_id) => dispatch(getTQS(tq_id)),
  setStep: (step) => dispatch(setStep(step)),
  setContent: (content) => dispatch(setContent(content)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);