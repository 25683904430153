import React from 'react';
import PropTypes from 'prop-types';
import { getLanguageResources, getLanguages, setLanguage } from '../../store/actions/languageActions'
import { IconButton, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem } from '@material-ui/core';
import { getTQS, getTqModelById } from '../../store/actions/tqActions';
import { Language } from '@material-ui/icons';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import languageType from '../../types/language';
import currentUserType from '../../types/currentUser';
import surveyType from '../../types/survey';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(0,0,0,0.5) !important',
    borderRadius: '10%',
    color: '#FFFFFF',
    marginTop: '15px',
    zIndex: '1 !important',
  },
  icon: {
    color: '#FFFFFF',
  },
}));

const LangaugePicker = ({ getLanguageResources, getLanguages, languages, currentUser, setLanguage, getTQS, getTqModelById, activeSurvey }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const classes = useStyles();

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  React.useEffect(() => {
    getLanguages();
    if (currentUser.user_id) {
      getLanguageResources(parseInt(currentUser.language_id));
    } else {
      getLanguageResources("1");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleLanguageClick = (language_id) => {
    if(currentUser.user_id) {
      setLanguage(currentUser.user_id, parseInt(language_id));
      getLanguageResources(parseInt(language_id));
      if(activeSurvey.tq_id) {
        setTimeout(() => {
          getTQS(activeSurvey.tq_id);
          getTqModelById(activeSurvey.tq_model_id);
        }, 250);
      }
    } else {
      getLanguageResources(parseInt(language_id));
    }
  }
  
  return (
    <>
      <IconButton
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        onMouseEnter={handleToggle}
        style={{
          position: 'absolute',
          right: '10px',
          bottom: '8px'
        }}
      >
        <Language className={classes.icon} />
      </IconButton>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper className={classes.root}>
                <ClickAwayListener onClickAway={handleClose}>
                  {
                    (languages.length > 0) ? (
                      <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    {
                    languages.map((language) => {
                      return <MenuItem key={language.langauge_id} onClick={() => handleLanguageClick(language.language_id)}>
                        {language.language}
                      </MenuItem>
                    })}
                  </MenuList>
                    ) : (
                      <span />
                    )
                  }
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
    </>
  );
}

LangaugePicker.propTypes = {
  getLanguageResources: PropTypes.func,
  getLanguages: PropTypes.func,
  setLanguage: PropTypes.func,
  languages: PropTypes.arrayOf(languageType),
  currentUser: currentUserType,
  getTQS: PropTypes.func,
  getTqModelById: PropTypes.func,
  activeSurvey: surveyType,
};

LangaugePicker.defaultProps = {
  getLanguageResources: () => null,
  getLanguages: () => null,
  setLanguage: () => null,
  languages: [],
  currentUser: {},
  getTQS: () => null,
  getTqModelById: () => null,
  activeSurvey: {},
};

const mapStateToProps = (state) => ({
  languages: state.language.languages,
  currentUser: state.auth.currentUser,
  activeSurvey: state.survey.activeSurvey,
});

const mapDispatchToProps = (dispatch) => ({
  getLanguageResources: (langauge_id) => dispatch(getLanguageResources(langauge_id)),
  getLanguages: () => dispatch(getLanguages()),
  setLanguage: (user_id, language_id) => dispatch(setLanguage(user_id, language_id)),
  getTQS: (tq_id) => dispatch(getTQS(tq_id)),
  getTqModelById: (tq_model_id) => dispatch(getTqModelById(tq_model_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LangaugePicker);