import { LOGIN, AUTH_ERROR, SET_CURRENT_USER, LOGOUT, RESET_USER, FORBIDDEN_API } from '../actions/authActions';

const initState = {
  currentUser: {},
  authError: null,
  apiError: null,
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        authError: null,
      }
    case AUTH_ERROR:
      return {
        ...state,
        authError: action.payload,
      }
    case LOGOUT:
      return {
        ...state,
        currentUser: {},
      }
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
      }
    case RESET_USER:
      return {
        ...state,
      }
    case FORBIDDEN_API:
      return {
        ...state,
        apiError: action.payload,
      }
    default:
      return state;
  }
}

export default authReducer;