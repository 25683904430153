import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CentralBox, HeaderTitle, StyledDivider, SubHeader, BackButton, BottomBlueBox, SendButton, BackButtonMobile, TopBlueBox } from '../common/index.styled';
import currentUserType from '../../types/currentUser';
import teamType from '../../types/team';
import { FormControl, Typography, List, ListItem, ListItemText, Divider } from '@material-ui/core';
import { setContent, setStep } from '../../store/actions/navigationActions';
import { setDeadline } from '../../store/actions/surveyActions';
import Translate from '../Translate/Translate';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardBackspace, DoubleArrow, People, Whatshot } from '@material-ui/icons';
import { updateTQS } from '../../store/actions/tqActions';
import moment from 'moment';
import surveyFull from '../../types/surveyFull';

const useStyles = makeStyles((theme) => ({
  formControlResource: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
    width: '62%',
  },
  peopleIcon: {
    fontSize: '50px',
    color: '#CCBD74',
    textAlign: 'center',
    margin: 'auto',
  },
  deadlineIcon: {
    color: '#CC9274',
    fontSize: '45px',
    marginBottom: '-10px',
  },
  deleteIcon: {
    marginLeft: '35px',
  },
  text: {
    color: '#5F5F5F',
    fontSize: '13px',
  },
  textLarge: {
    color: '#5F5F5F',
    fontSize: '20px',
  },
  inputContainer: {
    marginTop: '15px',
  },
  halfRoundedField: {
    borderBottomLeftRadius: '25px !important',
    borderTopLeftRadius: '25px !important'
  },
  list: {
    overflowY: 'scroll',
    maxHeight: '150px',
  },
  leader: {
    fontWeight: 'bold',
  }
}));

const Review = ({
  selectedTeam, setContent, setStep, updateTQS, deadline, setDeadline, survey,
}) => {
  const classes = useStyles();

  const handleBackClick = () => {
    setContent('deadline');
    setStep(2);
  }

  const handleSendClick = () => {
    updateTQS(survey.tq_id, deadline);
    setContent('confirmation');
    setStep(4);
  }

  React.useEffect(() => {
    if (!deadline) {
      setDeadline(moment().add(2, 'days').format('YYYY-MM-DD'));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CentralBox>
      <TopBlueBox>
        <BackButtonMobile variant='contained' onClick={() => handleBackClick()}>
          <KeyboardBackspace />
          <Translate keyValue='back_label' feature='teamquality/app/button' />
        </BackButtonMobile>
        <SendButton
          variant='contained'
          onClick={() => handleSendClick()}
        >
          <Translate keyValue='send_label' feature='teamquality/app/button' />
          <DoubleArrow />
        </SendButton>
      </TopBlueBox>
      <BackButton variant='contained' onClick={() => handleBackClick()}>
        <KeyboardBackspace />
        <Translate keyValue='back_label' feature='teamquality/app/button' />
      </BackButton>
      <HeaderTitle>
        <Translate keyValue='title' feature='teamquality/app/invite' />
      </HeaderTitle>
      <StyledDivider />
      <FormControl className={classes.formControlResource} >
        <SubHeader>
          <Translate keyValue='team_label' feature='teamquality/app' />
        </SubHeader>
      </FormControl>
      <FormControl className={classes.formControlResource}>
        <People className={classes.peopleIcon} />
      </FormControl>
      <FormControl className={classes.formControlResource}>
        <Typography className={classes.text}>
          - {selectedTeam.team} -
        </Typography>
      </FormControl>
      <FormControl className={classes.formControlResource}>
        <Typography className={classes.textLarge}>
          {selectedTeam.members.length} <Translate keyValue='member_list_label' feature='teamquality/app/invite' />
        </Typography>
      </FormControl>
      <FormControl classes={classes.formControlResource}>
        <div className={classes.list}>
          <List>
            {
              selectedTeam.members.map((member) => {
                return (member.leader) ? (
                  <div>
                    <ListItem>
                        <ListItemText className={classes.leader}>
                          <strong>
                           {member.username}
                          </strong>
                        </ListItemText>
                    </ListItem>
                    <Divider />
                  </div>
                ) : (
                  <span />
                )
              })
            }
            {
              selectedTeam.members.map((member) => {
                return (!member.leader) ? (
                  <div>
                    <ListItem>
                        <ListItemText>
                          {member.username}
                        </ListItemText>
                    </ListItem>
                    <Divider />
                  </div>
                ) : (
                  <span />
                )
              })
            }
          </List>
        </div>
      </FormControl>
      <FormControl className={classes.formControlResource}>
        <Typography className={classes.textLarge}>
          <Whatshot className={classes.deadlineIcon} />
          <Translate keyValue='deadline_subtitle_1' feature='teamquality/app/invite' />
        </Typography>
      </FormControl>
      <FormControl className={classes.formControlResource}>
        <Typography className={classes.text}>
          <Translate keyValue='deadline_text_3' feature='teamquality/app/invite' />
        </Typography>
      </FormControl>
      <BottomBlueBox>
        <SendButton
          variant='contained'
          onClick={() => handleSendClick()}
        >
          <Translate keyValue='send_label' feature='teamquality/app/button' />
          <DoubleArrow />
        </SendButton>
      </BottomBlueBox>
    </CentralBox>
  )
};

Review.propTypes = {
  currentUser: currentUserType,
  selectedTeam: teamType,
  setContent: PropTypes.func,
  setStep: PropTypes.func,
  updateTQS: PropTypes.func,
  deadline: PropTypes.string,
  reminder: PropTypes.string,
  setDeadline: PropTypes.func,
  survey: surveyFull,
};

Review.defaultProps = {
  currentUser: {},
  selectedTeam: {},
  setContent: () => null,
  setStep: () => null,
  updateTQS: () => null,
  deadline: null,
  reminder: null,
  setDeadline: () => null,
  survey: {},
};

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
  selectedTeam: state.team.selectedTeam,
  deadline: state.survey.deadline,
  reminder: state.survey.reminder,
  survey: state.tq.survey,
});

const mapDistpatchToProps = (dispatch) => ({
  setContent: (content) => dispatch(setContent(content)),
  setStep: (step) => dispatch(setStep(step)),
  updateTQS: (tq_id, deadline_at) => dispatch(updateTQS(tq_id, deadline_at)),
  setDeadline: (date) => dispatch(setDeadline(date)),
});

export default connect(mapStateToProps, mapDistpatchToProps)(Review);