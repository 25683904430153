import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import currentUserType from '../../types/currentUser';
import { useAsync } from 'react-async';
import { login, resetAuthError } from '../../store/actions/authActions';
import { connect, useDispatch } from 'react-redux';
import { LoginPageContainer, CentralBox, LoginLargeButton, HeaderTitle, StyledDivider, TextButtonDark, TextButtonLight, BottomBlueBox, LoginAvatar } from '../../components/common/index.styled';
import FormInput from '../../components/common/FormInput';
import { Redirect } from 'react-router-dom';
import ResetPassword from '../../components/ResetPassword/ResetPassword';
import ResetPasswordConfirm from '../../components/ResetPasswordConfirm/ResetPasswordConfirm';
import PrivacyPolicy from '../../components/PrivacyPolicy/PrivacyPolicy';
import { ChangeHistory } from '@material-ui/icons';
import TransparentNavbar from '../../components/Navbar/TransparentNavbar';
import Translate from '../../components/Translate/Translate';
import logo from '../../assets/img/logo.png';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const formDataFields = {
  email: 'email',
  password: 'password'
};

const isVerified = async ([email]) => {
  if (email) {
    try {
      if (true) {
        return true;
      }
    } catch (err) {
      return false;
    }
  }
  // Return default value as false
  return false;
};

const Login = ({ currentUser, authError, resetAuthError }) => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const [activeContent, setActiveContent] = React.useState('');

  const handleClose = (event, reason) => {
    resetAuthError();
  };

  const handleFormDataChange = (field, value) => {
    setData({
      ...data,
      [field]: value,
    });
    setErrors({ ...omit(errors, [field]), })
  }

  const { run: validateEmail } = useAsync({
    deferFn: isVerified,
    onResolve: isValid => {
      if (isValid) {
        dispatch(login(data.email, data.password));
        return;
      }
      setErrors({ [formDataFields.email]: 'Invalid email' })
    }
  });

  const switchContent = (content) => {
    setActiveContent(content);
  };

  const renderContent = () => {
    switch (activeContent) {
      case 'resetPassword':
        return <ResetPassword setActiveContent={setActiveContent} />
      case 'privacyPolicy':
        return <PrivacyPolicy setActiveContent={setActiveContent} />
      case 'confirmation':
        return <ResetPasswordConfirm setActiveContent={setActiveContent} />
      default:
        return (
          <CentralBox>
            <HeaderTitle>
              <LoginAvatar src={logo} />
              <br />
              <Translate keyValue='title' feature='teamquality/app/login' />
            </HeaderTitle>
            <StyledDivider />
            {(authError) ? (
                authError.includes('401') ? (
                  <Snackbar open={authError.includes('401')} onClose={handleClose} anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}>
                  <Alert onClose={handleClose} severity="error">
                    <Translate  keyValue='wrong_credentials' feature='teamquality/app/login/error' />
                  </Alert>
                </Snackbar>
                ) : (
                  <span />
                )
              ) : (
                <span />
              )}
            <div style={{ marginTop: '70px' }}>
              <FormInput
                value={get(data, formDataFields.email, '')}
                error={get(errors, formDataFields.email)}
                onChange={(value) => handleFormDataChange(formDataFields.email, value)}
                label={<Translate keyValue='emailaddress' feature='teamquality/app' />}
                className='inputRounded'
              />
              <FormInput
                value={get(data, formDataFields.password, '')}
                onChange={(value) => handleFormDataChange(formDataFields.password, value)}
                label={<Translate keyValue='password' feature='teamquality/app/login' />}
                type="password"
                className='inputRounded'
              />
              <LoginLargeButton
                variant='contained'
                onClick={() => validateEmail(get(data, formDataFields.email))}
                startIcon={<ChangeHistory style={{ color: '#CCBD74' }} />}
              > 
                <Translate keyValue='login' feature='teamquality/app/login' />
              </LoginLargeButton>
              <br />
              
              <TextButtonDark
                variant='text'
                onClick={() => switchContent('resetPassword')}
              >
                <Translate keyValue='forgot_password_label' feature='teamquality/app/login' />
              </TextButtonDark>
            </div>
            <BottomBlueBox>
              <TextButtonLight
                variant='text'
                onClick={() => switchContent('privacyPolicy')}
              >
                <Translate keyValue='privacy_policy_label' feature='teamquality/app/login' />
              </TextButtonLight>
            </BottomBlueBox>
          </CentralBox>
        )
    }
  }

  return (!isEmpty(currentUser)) ? (
    <Redirect to="/" />
  ) : (
    <>
      <LoginPageContainer>
        <TransparentNavbar />
        {renderContent()}
      </LoginPageContainer>
    </>
  )
}

Login.propTypes = {
  onSubmit: PropTypes.func,
  currentUser: currentUserType,
  authError: PropTypes.string,
  resetAuthError: PropTypes.func,
};

Login.defaultProps = {
  onSubmit: () => null,
  currentUser: {},
  authError: '',
  resetAuthError: () => null,
};

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
  authError: state.auth.authError,
});

const mapDispatchToProps = (dispatch) => ({
  resetAuthError: () => dispatch(resetAuthError()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Login);