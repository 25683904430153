import { getTq, getTqModel, updateTq, updateTqAnswer } from '../../services/tqService';
import { FORBIDDEN_API } from './authActions'

export const GET_TQ = 'GET_TQ';
export const UPDATE_TQ = 'UPDATE_TQ';
export const UPDATE_TQ_ANSWER = 'UPDATE_TQ_ANSWER';
export const GET_TQ_MODEL = 'GET_TQ_MODEL';

export const getTqModelById = (tq_model_id) => dispatch => {
  const options = {
    headers: {
      'content-type': 'application/json',
      'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
    },
  };

  getTqModel({ tq_model_id, options }).then((response) => {
    dispatch({ type: GET_TQ_MODEL, payload: response.data });
  }).catch((error) => {
    dispatch({ type: FORBIDDEN_API, payload: error.message });
  });
};

export const getTQS = (tq_id) => (dispatch) => {
    const options = {
      headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
      },
    };
    getTq({ tq_id, options }).then((response) => {
      dispatch({ type: GET_TQ, payload: response.data});
    }).catch((error) => {
      dispatch({ type: FORBIDDEN_API, payload: error.message });
    });
};

export const updateTQS = (tq_id, deadline_at) => (dispatch) => {
    const options = {
      headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
      },
    };
    const data = {
      deadline_at,
    };
    updateTq({ tq_id, data, options }).then((response) => {
      dispatch({ type: UPDATE_TQ, payload: response.data });
    }).catch((error) => {
      dispatch({ type: FORBIDDEN_API, payload: error.message });
    });
}

export const updateTQAnswer = (tq_id, answers) => (dispatch) => {
    const options = {
      headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
      },
    };
  
    const data = {
      answered: true,
      tq_answer_question: answers,
    };
  
    updateTqAnswer({ tq_id, data, options }).then(() => {
      dispatch({ type: UPDATE_TQ_ANSWER });
    }).catch((error) => {
      dispatch({ type: FORBIDDEN_API, payload: error.message });
    });
};