import React from 'react';
import PropTypes from 'prop-types';
import { CentralBox, PrivacyPolicyContainer, HeaderTitle, StyledDivider, BackButton, BottomBlueBox } from '../common/index.styled';
import Translate from '../Translate/Translate';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { translate } from '../../helpers/translate';
import resourceType from '../../types/resource';
import { connect } from 'react-redux';
import { Remarkable } from 'remarkable';

const PrivacyPolicy = ({ setActiveContent, resources }) => {
  const [html, setHtml] = React.useState('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const md = new Remarkable();

  React.useEffect(() => {
    const text = translate(resources, 'teamquality/app/login', 'PRIVACY_POLICY_TEXT');
    const newText = text.replace(/^### (.*$)/gim, '<h3>$1</h3>')
		      .replace(/^## (.*$)/gim, '<h2>$1</h2>')
          .replace(/^# (.*$)/gim, '<h1>$1</h1>');
    const text2 = md.render(newText).replace(/&lt;/g, '<').replace(/&gt;/g, '>');
    setHtml(text2);
  }, [md, resources]);

  return (
    <CentralBox>
      <BackButton variant='contained' onClick={() => setActiveContent('')}>
        <KeyboardBackspaceIcon />
        <Translate keyValue='back_label' feature='teamquality/app/button' />
      </BackButton>
      <HeaderTitle>
        <Translate keyValue='privacy_policy_label' feature='teamquality/app/login' />
      </HeaderTitle>
      <StyledDivider />
      <PrivacyPolicyContainer>
        <div
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        ></div>
      </PrivacyPolicyContainer>
      <BottomBlueBox />
    </CentralBox>
  );
};

PrivacyPolicy.propTypes = {
  onSubmit: PropTypes.func,
  resources: PropTypes.arrayOf(resourceType),
};

PrivacyPolicy.defaultProps = {
  onSubmit: () => null,
  resources: [],
};

const mapStateToProps = state => ({
  resources: state.language.resources,
});

export default connect(mapStateToProps)(PrivacyPolicy);