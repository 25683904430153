import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import currentUserType from '../types/currentUser';

const PrivateLayout = ({ children, currentUser }) => {
  const isAuthenticated = () => !isEmpty(currentUser);

  return (isAuthenticated()) ? (
    <>
      {children}
    </>
  ) : (<Redirect to="/login" />)
};


PrivateLayout.propTypes = {
  children: PropTypes.node,
  currentUser: currentUserType,
};

PrivateLayout.defaultProps = {
  children: <div />,
  currentUser: {},
};

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
});

export default connect(mapStateToProps, null)(withRouter(PrivateLayout));
