import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { setTeamFromLink } from '../../store/actions/teamActions';
import { connect } from 'react-redux';

const DataCollector = ({ setTeamFromLink, setPurpose }) => {

  React.useState(() => {
    const team_id = window.location.href.split('/').pop();
    setTeamFromLink(team_id);
  }, []);

  return (
    <Redirect to='/login' />
  )
};

DataCollector.propTypes = {
  setTeamFromLink: PropTypes.func,
};

DataCollector.defaultProps = {
  setTeamFromLink: () => null,
};

const mapDispatchToProps = (dispatch) => ({
  setTeamFromLink: (team_id) => dispatch(setTeamFromLink(team_id)),
})

export default connect(null, mapDispatchToProps)(DataCollector);