import PropTypes from 'prop-types';
import questionType from '../question';

export default PropTypes.shape({
  tq_model_area_id: PropTypes.string,
  color: PropTypes.string,
  area: PropTypes.string,
  description: PropTypes.string,
  list_description: PropTypes.string,
  list_bottom: PropTypes.arrayOf(questionType),
});