import React from 'react';
import PropTypes from 'prop-types';
import resourceType from '../../types/resource';
import currentUserType from '../../types/currentUser';
import teamType from '../../types/team';
import surveyFullType from '../../types/surveyFull';
import { connect } from 'react-redux';
import { translate } from '../../helpers/translate';

const Translate = ({ resources, feature, keyValue, currentUser, selectedTeam, deadline, reminder, survey}) => {
  const [text, setText] = React.useState('');

  React.useEffect(() => {
    if (resources.length > 0) {
      const original = translate(resources, feature, keyValue);
      if(original.includes('{user_name}')) {
        setText(original.replace('{user_name}', currentUser.firstname));
      } else if(original.includes('{team_name}')) {
        setText(original.replace('{team_name}', selectedTeam.team));
      } else if(original.includes('{invited_by}')) {
        selectedTeam.members.forEach((member) => {
          if(member.leader) {
            setText(original.replace('{invited_by}', member.user_fullname));
          }
        })
      } else if(original.includes('{deadline_date}') || original.includes('{reminder_date}')) {
        const temp = original.replace('{deadline_date}', deadline)
        setText(temp.replace('{reminder_date}', reminder));
      } else if(original.includes('{num_answered}') || original.includes('{num_members}')) {
        let count = 0;
        survey.answers.forEach((answer) => {
          if(answer.answered) {
            count++;
          }
        });
        const temp = original.replace('{num_members}', survey.answers.length);
        setText(temp.replace('{num_answered}', count + 1));
      } else {
        setText(original)
      }
    }
  }, [currentUser, selectedTeam, feature, keyValue, resources, deadline, reminder, survey]);

  return (
    text
  )

};

Translate.propTypes = {
  resources: PropTypes.arrayOf(resourceType),
  currentUser: currentUserType,
  selectedTeam: teamType,
  deadline: PropTypes.string,
  reminder: PropTypes.string,
  survey: surveyFullType,
}

Translate.defaultProps = {
  resources: [],
  currentUser: {},
  selectedTeam: {},
  deadline: '',
  reminder: '',
  survey: {},
};

const mapStateToProps = (state) => ({
  resources: state.language.resources,
  currentUser: state.auth.currentUser,
  selectedTeam: state.team.selectedTeam,
  deadline: state.survey.deadline,
  reminder: state.survey.reminder,
  survey: state.tq.survey,
});

export default connect(mapStateToProps)(Translate);