import React from 'react';
import PropTypes from 'prop-types';
import { CentralBox, PageContainer } from '../../components/common/index.styled';
import TeamPicker from '../../components/HomepageFlow/TeamPicker';
import MemberManagement from '../../components/HomepageFlow/MemberManagement';
import Deadline from '../../components/HomepageFlow/Deadline';
import Review from '../../components/HomepageFlow/Review';
import Confirmation from '../../components/HomepageFlow/Confirmation';
import SurveyIntro from '../../components/HomepageFlow/SurveyIntro';
import Survey from '../../components/HomepageFlow/Survey';
import AlreadyAnswered from '../../components/HomepageFlow/AlreadyAnswered';
import TQSConfirmation from '../../components/HomepageFlow/TQSConfirmation';
import NothingToDo from '../../components/HomepageFlow/NothingToDo';
import Navbar from '../../components/Navbar';
import { connect } from 'react-redux';
import { logout } from '../../store/actions/authActions';

const Homepage = ({ activeContent, apiError, logout }) => {

  React.useEffect(() => {
    if(apiError) {
      if(apiError.includes('401')) {
        logout();
      }
    }
  }, [apiError, logout]);

  const render = () => {
    switch(activeContent) {
      case 'teamPicker':
        return <TeamPicker />;
      case 'memberManagement':
        return <MemberManagement />;
      case 'deadline':
        return <Deadline />
      case 'review':
        return <Review />
      case 'confirmation':
        return <Confirmation />
      case 'surveyIntro':
        return <SurveyIntro />
      case 'survey':
        return <Survey />;
      case 'alreadyAnswered':
        return <AlreadyAnswered />;
      case 'tqsConfirmation':
        return <TQSConfirmation />;
      case 'nothingToDo':
        return <NothingToDo />;
      default:
        return <div />;
    }
  }

  return (
    <>
      <PageContainer>
        <Navbar />
        <CentralBox>
          {render()}
        </CentralBox>
      </PageContainer>
    </>
  )
};

Homepage.propTypes = {
  activeContent: PropTypes.string,
  apiError: PropTypes.string,
  logout: PropTypes.func,
};

Homepage.defaultProps = {
  activeContent: 'teamPicker',
  apiError: '',
  logout: () => null,
};

const mapStateToPros = (state) => ({
  activeContent: state.navigation.currentContent,
  apiError: state.auth.apiError,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

export default connect(mapStateToPros, mapDispatchToProps)(Homepage);