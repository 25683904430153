import { SET_DEADLINE, SET_REMINDER, SET_ACTIVE_SURVEY } from '../actions/surveyActions';

const initState = {
  deadline: null,
  reminder: null,
  activeSurvey: {},
};

const surveyReducer = (state = initState, action) => {
  switch(action.type) {
    case SET_DEADLINE:
      return {
        ...state,
        deadline: action.payload,
      };
    case SET_REMINDER:
      return {
        ...state,
        reminder: action.payload,
      }
    case SET_ACTIVE_SURVEY:
      return {
        ...state,
        activeSurvey: action.payload,
      };
    default:
      return state;
  }
};

export default surveyReducer;