import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { StyledStepper } from '../../components/common/index.styled';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '75%',
    background: 'transparent',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['', '', '', ''];
}

const MyStepper = ({ active }) => {
  const classes = useStyles();
  const steps = getSteps();

  return (
    <div className={classes.root}>
      <StyledStepper activeStep={active} style={{ background: 'none'}} >
        {steps.map((label) => (
          <Step key={Math.floor(Math.random() * 1000)}>);
            <StepLabel key={Math.floor(Math.random() * 1000)}>{label}</StepLabel>
          </Step>
        ))}
      </StyledStepper>
    </div>
  );
};

export default MyStepper;